<template>
	<div class="p-error">
		<div class="container">
			<img class="p-error__img" src="@/assets/404.jpg" width="679" height="455" alt="404" />
			<div class="p-error__title">ERROR</div>
			<p>Page not found</p>
			<router-link class="p-error__btn" to="/">Back to Main</router-link>
		</div>
	</div>
</template>

<script>
export default {
	name: 'page-error'
};
</script>

<style lang="scss">
.p-error
{
	display: flex;
	flex-direction: row;
	align-items: center;
	min-height: 100vh;
	padding: 30px 5px;
	line-height: 1;
	font-size: 2.2rem;
	color: rgba(#484E55, 0.8);
	text-align: center;

	&__img
	{
		vertical-align: middle;
		margin-bottom: 20px;
		max-width: 100%;
		height: auto;
	}

	&__title
	{
		margin-bottom: 10px;
		font-size: 5rem;
		font-weight: 700;
		color: #484E55;
	}

	&__btn
	{
		display: inline-flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		max-width: 300px;
		height: 60px;
		margin-top: 35px;
		padding: 10px;
		font-size: 1.8rem;
		text-decoration: none;
		outline: none !important;
		color: #FFF;
		background-color: #07CA84;
		box-shadow: 0px 5px 15px rgba(7, 202, 132, 0.4), 0px 25px 60px #B1FFE3;
		border-radius: 10px;
		transition: background-color 300ms ease-in-out, box-shadow 300ms ease-in-out;

		&:hover,
		&:focus
		{
			background-color: #14b77c;
			box-shadow: 0px 5px 10px rgba(7, 202, 132,1), 0px 25px 60px #B1FFE3;
		}
	}
}
</style>
